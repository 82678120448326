import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Children } from '@mocks';
import { Theme, Thumbnail } from '@package';

import type { Glyphs } from '@lookiero/aurora-iconfont';

export const ThumbnailChapter = () => {
  const { number, object, text, select } = useStoryteller();

  const { iconGlyphs } = Theme.get();

  return (
    <Thumbnail
      alt={text('alt', 'Image')}
      height={number('height', 250)}
      icon={
        select('icon', 'expand', {
          hint: 'Icon will only be shown if src is not set',
          options: Object.keys(iconGlyphs),
        }) as keyof Glyphs
      }
      src={text('src', 'https://images.pexels.com/photos/2820587/pexels-photo-2820587.jpeg')}
      srcSet={text(
        'srcSet',
        'https://via.placeholder.com/256/?text=image 1x, https://via.placeholder.com/512/?text=image 2x',
        { multiLine: true },
      )}
      style={object('style', {})}
      width={number('width', 187)}
    >
      <Children />
    </Thumbnail>
  );
};

ThumbnailChapter.displayName = '⚛️ Atoms/Thumbnail';
