// TODO: This is not a real "hook"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSupportedProps = (props: Record<string, any> = {}, supported: string[] = []): Record<string, any> => {
  const safeProps: Record<string, unknown> = {};

  for (const prop in props) {
    if (supported.includes(prop)) safeProps[prop] = props[prop];
  }

  return safeProps;
};
