// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isValidEmail } from './isValidEmail';
import { isValidPhone } from './isValidPhone';

import type { Fields } from '../components/atoms/InputField/variants/Fieldset/Fieldset';
import type { InputValue } from '../components/primitives/Input/Input.definition';

export interface InputError {
  required?: boolean;
  minLength?: boolean;
  regexp?: boolean;
  test?: boolean;
  format?: boolean;
}

export interface InputErrorsParams {
  minLength?: number;
  regexp?: RegExp;
  required?: boolean;
  test?: (value: unknown) => boolean;
  type?: string;
  value?: string | number | Record<keyof Fields, InputValue>;
}

export const getInputErrors = ({
  minLength = 0,
  regexp,
  required,
  test,
  type = 'text',
  value = '',
}: InputErrorsParams = {}): InputError | undefined => {
  const errors: InputError = {};

  // Common
  if (required && (value === undefined || value.length === 0)) errors.required = true;
  if (minLength > 0 && value.length < minLength) errors.minLength = true;
  if (regexp && regexp.exec && regexp.exec(value) === null) errors.regexp = true;
  if (test && !test(value)) errors.test = true;
  // Email & Phone format
  if ((type === 'email' && !isValidEmail(value)) || (type === 'tel' && !isValidPhone(value))) {
    errors.format = true;
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
};
