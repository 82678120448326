import { Chapter, Storyteller, serializeName } from '@lookiero/storyteller';
import * as Font from 'expo-font';
import React, { useState } from 'react';
import { Platform, StatusBar } from 'react-native';

import { Button } from '@atoms';
import { SPACE } from '@hooks';
import { Aurora, SafeAreaView, Text, View } from '@primitives';
import * as chapters from '@storyteller';
import { DefaultTheme, ThemeOutfittery } from '@theming';

import PKG from './package.json';

import type { ThemeDefinition } from './src/theming/Theme.definition';
import type { FC } from 'react';
import type { ViewStyle } from 'react-native';

const { version } = PKG;
const ENV_JEST = process.env.JEST_WORKER_ID !== undefined;
const FONTS = {
  'AreaInktrap-Semibold': require('./assets/fonts/AreaInktrap-Semibold.otf'),
  'AreaNormal-Semibold': require('./assets/fonts/AreaNormal-Semibold.otf'),
  'AreaNormal-Extrabold': require('./assets/fonts/AreaNormal-Extrabold.otf'),
  'OpenSans-Regular': require('./assets/fonts/OpenSans-Regular.ttf'),
  'OpenSans-Bold': require('./assets/fonts/OpenSans-Bold.ttf'),
  'Dala-Floda': require('./assets/fonts/DalaFloda-Bold.otf'),
  auroraicons: require('@lookiero/aurora-iconfont/dist/auroraicons.ttf'),
} as const;

const style = {
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidth: {
    flex: 1,
  },
};

const ThemeToggle: FC<{ theme: ThemeDefinition; setTheme: (t: ThemeDefinition) => void }> = ({ theme, setTheme }) => {
  return (
    <View style={style.buttonContainer as ViewStyle}>
      <Button small wide={false} onPress={() => setTheme(theme === ThemeOutfittery ? DefaultTheme : ThemeOutfittery)}>
        Toggle theme.
      </Button>
      <Text margin={SPACE.SPACE_3}>Current theme: {theme === ThemeOutfittery ? 'Outfittery' : 'Lookiero'}</Text>
    </View>
  );
};

const App = () => {
  const [ready, setReady] = useState(false);
  const [theme, setTheme] = useState(DefaultTheme);

  React.useEffect(() => {
    if (!ENV_JEST) Font.loadAsync(FONTS).then(() => setReady(true));
  }, []);

  const Container = Platform.OS !== 'web' ? SafeAreaView : React.Fragment;

  return !ready ? (
    <></>
  ) : (
    <>
      <Aurora theme={theme}>
        <StatusBar />
        <Container style={style.fullWidth}>
          <ThemeToggle theme={theme} setTheme={setTheme} />
          <View nativeID={'container'} style={style.fullWidth}>
            <Storyteller title="Aurora" version={version} nativeID={'storyteller'}>
              {Object.values(chapters).map((chapter, index) => {
                // @ts-expect-error Just for better UX in Storyteller
                const { domain = '', name: component = '' } = serializeName(chapter.displayName);
                const base = domain.split(' ')[1] || '';

                return React.createElement(
                  Chapter,
                  {
                    doc: `https://github.com/lookiero/aurora-design-system/blob/master/__storyteller__/${base.toLowerCase()}/${component}.chapter.jsx`,
                    jsx: false,
                    key: `chapter:${index}`,
                    // @ts-expect-error Just for better UX in Storyteller
                    title: chapter.displayName,
                  },
                  React.createElement(chapter),
                );
              })}
            </Storyteller>
          </View>
        </Container>
      </Aurora>
    </>
  );
};

export default App;
