import StyleSheet from 'react-native-extended-stylesheet';

import type { INFOBOX_TYPE } from './InfoBox.definition';
import type { ALIGN } from '../../../hooks/useStyler';
import type { FlexStyle } from 'react-native';

const borderRadiusVariantStyleRecord: Record<INFOBOX_TYPE, { borderRadius: string | number }> = {
  rounded: {
    borderRadius: '$borderRadius3',
  },
  squared: {
    borderRadius: 0,
  },
};

const borderRadiusVariantStyle = StyleSheet.create(borderRadiusVariantStyleRecord);

const contentAlignStyleRecord: Record<ALIGN.LEFT | ALIGN.CENTER, Pick<FlexStyle, 'justifyContent'>> = {
  left: {
    justifyContent: 'flex-start',
  },
  center: {
    justifyContent: 'center',
  },
};

const contentAlignStyle = StyleSheet.create(contentAlignStyleRecord);

const style = StyleSheet.create({
  container: {
    padding: '$space3',
  },
  textContainer: {
    flexShrink: 1,
  },
  paddingTop: {
    paddingTop: '$space1',
  },
});

export { style, borderRadiusVariantStyle, contentAlignStyle };
