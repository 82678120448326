import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  input: {
    flex: 1,
  },
  prefix: {
    paddingLeft: '$space4',
    ...Platform.select({
      ios: {
        paddingTop: '$space4',
      },
    }),
  },
});
