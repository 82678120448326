import { useStoryteller } from '@lookiero/storyteller';
import React, { useState } from 'react';
import { Platform } from 'react-native';

import { Aurora, COLOR, Footer, FooterItem, View } from '@package';

export const FooterChapter = () => {
  const { boolean, event, object, select, viewport } = useStoryteller();

  const [value, setValue] = useState('lookiero');

  const handleChange = (changedValue: string) => {
    event('onChange', [changedValue]);
    setValue(changedValue);
  };

  const ITEM_PROPS = {
    color: select('item.color', COLOR.TEXT_MEDIUM, { options: Object.values(COLOR) }) as COLOR,
    colorActiveIcon: select('item.colorActiveIcon', COLOR.ICON, { options: Object.values(COLOR) }) as COLOR,
    colorActiveText: select('item.colorActiveText', COLOR.TEXT, { options: Object.values(COLOR) }) as COLOR,
  };

  return (
    <Aurora>
      <View
        style={
          Platform.OS === 'web'
            ? {
                minHeight: viewport.height,
                maxHeight: viewport.height,
              }
            : // eslint-disable-next-line react-native/no-inline-styles
              { flex: 1 }
        }
      >
        <Footer
          borderColor={select('borderColor', COLOR.BORDER_INTERACTIVE, { options: Object.values(COLOR) }) as COLOR}
          color={select('color', COLOR.BG_BASE, { options: Object.values(COLOR) }) as COLOR}
          value={value}
          onChange={handleChange}
          style={object('style', {
            borderStyle: 'solid',
            borderTopWidth: 1,
            bottom: 0,
            position: 'absolute',
          })}
        >
          <FooterItem {...ITEM_PROPS} active icon="box_fill" text="Caja" value="box" />
          <FooterItem {...ITEM_PROPS} icon="shop" text="Shop" value="shop" />
          <FooterItem
            {...ITEM_PROPS}
            icon="lookiero"
            text="Inicio"
            value="lookiero"
            notification={boolean('notification', true)}
          />
          <FooterItem {...ITEM_PROPS} icon="referrals" text="Amigas" value="referrals" />
          <FooterItem {...ITEM_PROPS} icon="profile" text="Perfil" value="profile" />
        </Footer>
      </View>
    </Aurora>
  );
};

FooterChapter.displayName = '✳️ Molecules/Footer';
