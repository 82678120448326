import React, { forwardRef } from 'react';

import { COLOR, FLEX_DIRECTION, POINTER, SIZE, SPACE } from '@hooks';
import { Icon, Text, Touchable, VIEW_ROLES, View } from '@primitives';

import { style } from './Accordion.style';

import type { ViewProperties } from '../../primitives/View/View';
import type { StylerProperties } from '@hooks';
import type { ReactNode } from 'react';
import type { ViewComponent } from 'react-native';

export interface AccordionProps {
  active?: boolean;
  disabled?: boolean;
  divider?: boolean;
  text?: string | ReactNode;
  title: string;

  onPress?: (pressed: boolean) => void;
}

export type AccordionProperties = AccordionProps & StylerProperties & ViewProperties;

const Accordion = forwardRef<ViewComponent, AccordionProperties>(
  ({ active = false, children, disabled = false, divider = true, text, title, onPress, ...others }, ref) => (
    <View {...others} collapsable={false} role={VIEW_ROLES.article} ref={ref}>
      <Touchable
        paddingVertical={text ? SPACE.SPACE_2 : SPACE.SPACE_5}
        pointerEvents={disabled ? POINTER.NONE : undefined}
        style={[style.touchable, divider && style.divider]}
        tabIndex={0}
        wide
        onPress={!disabled && onPress ? () => onPress(!active) : undefined}
      >
        <View flexDirection={FLEX_DIRECTION.COLUMN} marginRight={SPACE.SPACE_4} flex={SIZE.XS}>
          <Text action color={disabled ? COLOR.TEXT_DISABLED : COLOR.TEXT} wide>
            {title}
          </Text>
          {!!text && (
            <Text color={disabled ? COLOR.TEXT_DISABLED : COLOR.TEXT_MEDIUM} ellipsizeMode={!active} style={style.text}>
              {text}
            </Text>
          )}
        </View>

        <View style={text && style.iconContainerWithText}>
          <Icon name={active ? 'collapse' : 'expand'} color={disabled ? COLOR.ICON_DISABLED : COLOR.ICON_LIGHT} />
        </View>
      </Touchable>
      {active && <View role={VIEW_ROLES.section}>{children}</View>}
    </View>
  ),
);

Accordion.displayName = 'Accordion';

export { Accordion };
