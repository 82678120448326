// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef, useEffect, useLayoutEffect, useState } from 'react';
import { Animated, Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

import { IS_JEST, capitalize, testID } from '@helpers';
import { styles, useBanProps, useBanStylerProps, useDevice, useStyler } from '@hooks';

import { buildStyle, calcEasing, calcState, getNativeDriver, toAnimatedValue } from './helpers';
import { BANNED_PROPS, MOTION_TIMING, MOTION_TYPES } from './Motion.definition';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { VIEW_ROLES } from '../View';
import type { FC } from 'react';
import type { ViewProps } from 'react-native';

export interface MotionProps {
  delay?: boolean;
  disabled?: boolean;
  duration?: number;
  forceFinish?: boolean;
  layout?: { height: number; width: number };
  loops?: number;
  role?: VIEW_ROLES;
  timing?: MOTION_TIMING;
  type?: MOTION_TYPES;
  value?: unknown;
  onCancel?: () => void;
  onFinish?: () => void;
}

const emptyFn = () => {};

export type MotionProperties = MotionProps & StylerProperties & Animated.AnimatedProps<ViewProps>;

const Motion: FC<MotionProperties> = forwardRef(
  (
    {
      children,
      delay = false,
      disabled,
      forceFinish = false,
      layout,
      loops = 1,
      role,
      timing = MOTION_TIMING.QUAD,
      type = MOTION_TYPES.EXPAND,
      value = {},
      onCancel = emptyFn,
      onFinish = emptyFn,
      ...others
    },
    ref,
  ) => {
    const [active, setActive] = useState(false);
    const [mount, setMount] = useState(false);
    const [state, setState] = useState(calcState({ value, layout }));

    useLayoutEffect(() => {
      setMount(true);
    }, []);

    useEffect(() => {
      if (!mount || (forceFinish && active)) return;

      if (JSON.stringify(Object.keys(value).sort()) !== JSON.stringify(Object.keys(state).sort())) {
        setState(() => {
          const nextState = calcState({ layout, state, value });
          animate(nextState);

          return nextState;
        });
      } else {
        animate(state);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay, loops, timing, type, value]);

    const animate = (nextState) => {
      if (IS_JEST) return;

      const duration = others.duration || StyleSheet.value(`$motion${capitalize(type)}`);
      const keys = Object.keys(value);

      const useNativeDriver = getNativeDriver(keys);

      const animations = Animated.parallel(
        keys
          .filter((key) => nextState[key] !== undefined)
          .map((key) =>
            Animated.timing(nextState[key], {
              toValue: toAnimatedValue(key, value[key], layout),
              delay: delay ? duration : 0,
              duration: disabled ? 0 : duration,
              easing: disabled ? undefined : calcEasing(timing),
              useNativeDriver,
            }),
          ),
      );

      const callbacks = ({ finished }) => {
        if (!finished) {
          animations.stop();
          onCancel();
        } else {
          onFinish();
        }

        if (forceFinish && !disabled) setActive(false);
      };

      if (loops === 1) animations.start(callbacks);
      else Animated.loop(animations, { iterations: loops }).start(callbacks);

      if (forceFinish && !disabled) setActive(true);
    };

    return (
      <Animated.View
        {...useBanProps(useBanStylerProps(others), BANNED_PROPS)}
        {...testID(others.testID)}
        accessibilityRole={Platform.OS === 'web' ? role : undefined}
        pointerEvents={others.pointerEvents || others.pointer}
        ref={ref}
        style={styles(
          ...useStyler(others, Motion.displayName, useDevice()).style,
          buildStyle(value, disabled || IS_JEST ? value : state),
        )}
      >
        {children}
      </Animated.View>
    );
  },
);

Motion.displayName = 'Motion';

export { Motion };
