import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { ALIGN, BUTTON_VARIANT, Button, ButtonIcon, COLOR, Panel, Text, Theme, View } from '@package';

export const PanelChapter = () => {
  const { boolean, event, object, select } = useStoryteller();

  const { space4, space5, space6, space10 } = Theme.get();

  return (
    <Panel
      align={select('align', ALIGN.LEFT, { options: [ALIGN.LEFT, ALIGN.RIGHT] }) as ALIGN}
      color={select('color', COLOR.BG_BASE, { options: Object.values(COLOR) }) as COLOR}
      hasOverlay={boolean('hasOverlay', true)}
      isVisible={boolean('isVisible', true)}
      style={object('style', {})}
      // @ts-expect-error Just for better UX in Storyteller
      onClose={() => event('onClose')}
    >
      <ButtonIcon
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ alignSelf: 'flex-end', marginRight: space4 }}
        name="close"
        color={COLOR.ICON}
        // @ts-expect-error Just for better UX in Storyteller
        onPress={() => event('onClose')}
      />
      <View
        style={{
          paddingVertical: space10,
          paddingHorizontal: space6,
        }}
      >
        <Text action level={1} style={{ marginBottom: space5 }}>
          Blog
        </Text>
        <Text action level={1} style={{ marginBottom: space5 }}>
          Tarjeta Regalo
        </Text>
        <Text action level={1} style={{ marginBottom: space5 }}>
          Ayuda y contacto
        </Text>
        <Text action level={1} style={{ marginBottom: space10 }}>
          Términos y condiciones
        </Text>
        <Button variant={BUTTON_VARIANT.PRIMARY} onPress={undefined} style={{ marginBottom: space4 }}>
          Crear mi perfil
        </Button>
        <Button variant={BUTTON_VARIANT.SECONDARY} onPress={undefined}>
          Entrar en mi perfil
        </Button>
      </View>
    </Panel>
  );
};

PanelChapter.displayName = '✳️ Molecules/Panel';
