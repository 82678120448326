import { DefaultTheme } from './theme.default';

import type { ThemeDefinition } from './Theme.definition';

export const ThemeOutfittery: ThemeDefinition = {
  ...DefaultTheme,

  $theme: 'outfittery',

  $colorText: '#34454e',
  $colorTextLight: '#ffffff',
  $colorTextMedium: '#5d6a71',
  $colorTextMediumLight: '#ced8dd',
  $colorTextActionPrimary: '#ffffff',
  $colorTextActionSecondary: '#34454e',
  // $colorTextActionOption: '#ffffff',
  $colorTextActionDisabled: '#999495',
  // $colorTextHighlight: '#2484c6',
  $colorTextError: '#f45545',
  $colorTextPromotion: '#256056',
  $colorTextOpacity: 'rgba(52, 69, 78, 0.70)',
  $colorTextOpacityLight: 'rgba(52, 69, 78, 0.30)',
  $colorBgBase: '#ffffff',
  $colorBgPrimaryLight: '#f8f7f7',
  $colorBgPrimaryMediumLight: '#ced8dd',
  $colorBgPrimaryMedium: '#b7b3b3',
  $colorBgPrimary: '#34454e',
  $colorBgSurface: '#eff2f3',
  $colorBgSurfaceLight: '#f3f6f7',
  $colorBgBurnt: '#913d3d',
  $colorBgRose: '#c66666',
  $colorBgOlive: '#5d734e',
  // $colorBgHighlight: '#2484c6',
  // $colorBgHighlightLight: '#00bbce',
  $colorBgWarning: '#fdb924',
  $colorBgWarningLight: '#f9f5cc',
  $colorBgSuccess: '#3da490',
  $colorBgPromotion: '#d6f1e9',
  $colorBgError: '#f45545',
  $colorBgErrorLight: '#ffe4e1',
  $colorBgInteractiveActive: '#f8f7f7',
  $colorBgInteractiveHover: '#ced8dd',
  $colorBgInteractiveDisabled: '#f8f7f7',
  $colorBgInteractiveSelected: '#2484c6',
  $colorBgActionPrimaryActive: '#2484c6',
  $colorBgActionPrimaryActiveStart: '#2484c6',
  $colorBgActionPrimaryActiveEnd: '#00bbce',
  $colorBgActionPrimaryHover: '#5b5757',
  $colorBgActionPrimaryPressed: '#716a6b',
  $colorBgActionPrimaryDisabled: '#ced8dd',
  $colorBgActionSecondaryActive: '#ffffff',
  $colorBgActionSecondaryHover: '#ced8dd',
  $colorBgActionSecondaryPressed: '#b7b3b3',
  $colorBgActionSecondaryDisabled: '#ced8dd',
  $colorBgOpacity: 'rgba(52, 69, 78, 0.70)',
  $colorBgOpacityLight: 'rgba(52, 69, 78, 0.30)',
  $colorBorderInput: '#ced8dd',
  $colorBorderInputFocus: '#34454e',
  $colorBorderInputError: '#f45545',
  $colorBorderInteractive: '#DAD8D8',
  $colorBorderInteractiveSelected: '#34454e',
  $colorBorderActionSecondary: '#2484c6',
  // $colorBorderOpacity: 'rgba(52, 69, 78, 0.70)',
  // $colorBorderOpacityLight: 'rgba(52, 69, 78, 0.30)',
  $colorIcon: '#34454e',
  $colorIconLight: '#5d6a71',
  $colorIconDisabled: '#ced8dd',
  $colorIconOnPrimary: '#ffffff',
  // $colorIconActionOption: '#ffffff',
  $colorIconHighlight: '#2484c6',
  $colorIconPromotion: '#256056',
  $colorIconError: '#f45545',
  $colorIconSuccess: '#3da490',
  $colorIconWarning: '#fdb924',
  $colorIconOpacity: 'rgba(52, 69, 78, 0.70)',
  $colorIconOpacityLight: 'rgba(52, 69, 78, 0.30)',

  $borderRadius1: 2,
  $borderRadius2: 2,
  $borderRadius3: 2,
  $borderRadius4: 2,
  $borderRadius5: 2,

  $fontMap: {
    Heading: 'OpenSans-Regular',
    Body: 'OpenSans-Regular',
    Action: 'OpenSans-Bold',
    Detail: 'OpenSans-Regular',
    DetailBold: 'OpenSans-Bold',
  },

  $fontHeadingSize1: 68,
  $fontHeadingHeight1: 68,
  $fontHeadingLetterSpacing1: 0,

  $fontHeadingSize2: 42,
  $fontHeadingHeight2: 44,
  $fontHeadingLetterSpacing2: 0,

  $fontHeadingSize3: 31,
  $fontHeadingHeight3: 34,
  $fontHeadingLetterSpacing3: 0,

  $fontBodySize1: 22,
  $fontBodyHeight1: 26,
  $fontBodyLetterSpacing1: 0,

  $fontBodySize2: 18,
  $fontBodyHeight2: 22,
  $fontBodyLetterSpacing2: 0,

  $fontBodySize3: 15,
  $fontBodyHeight3: 20,
  $fontBodyLetterSpacing3: 0,

  $fontActionSize1: 22,
  $fontActionHeight1: 26,
  $fontActionLetterSpacing1: 0,

  $fontActionSize2: 18,
  $fontActionHeight2: 22,
  $fontActionLetterSpacing2: 0,

  $fontActionSize3: 15,
  $fontActionHeight3: 20,
  $fontActionLetterSpacing3: 0,

  $fontDetailSize1: 13,
  $fontDetailHeight1: 17,
  $fontDetailLetterSpacing1: 0,

  $fontDetailSize2: 12,
  $fontDetailHeight2: 16,
  $fontDetailLetterSpacing2: 0,

  $fontDetailSize3: 10,
  $fontDetailHeight3: 14,
  $fontDetailLetterSpacing3: 0,

  $fontDetailBoldSize1: 13,
  $fontDetailBoldHeight1: 17,
  $fontDetailBoldLetterSpacing1: 0,

  $fontDetailBoldSize2: 12,
  $fontDetailBoldHeight2: 16,
  $fontDetailBoldLetterSpacing2: 0,

  $fontDetailBoldSize3: 10,
  $fontDetailBoldHeight3: 14,
  $fontDetailBoldLetterSpacing3: 0,
};
