import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  container: {
    color: '$colorText',
    borderWidth: 0,
    backgroundColor: 'transparent',
    height: '$inputSize',
    justifyContent: 'center',
    paddingRight: '$space4',
    paddingLeft: '$space4',
    textAlignVertical: 'center',
    width: '100%',
    ...Platform.select({
      web: {
        appearance: 'none',
        outlineWidth: 0,
        outlineStyle: 'none',
      },
      android: {
        left: '$space2',
      },
    }),
  },

  containerIOS: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '$colorBgPrimaryLight',
  },

  disabled: {
    color: '$colorTextDisabled',
  },

  item: {
    color: '$colorText',
  },

  itemIOS: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: () => StyleSheet.value('$space1 + $space05'),
  },

  pressableIconIOS: {
    alignItems: 'flex-start',
    alignContent: 'center',
    backgroundColor: '$colorBgPrimaryLight',
    paddingTop: '$space4',
    paddingLeft: '$space4',
  },
});
