import React from 'react';

import { ALIGN, Spinner, View } from '@package';

export const SpinnerChapter = () => (
  // eslint-disable-next-line react-native/no-inline-styles
  <View style={{ flex: 1 }} alignItems={ALIGN.CENTER} alignContent={ALIGN.CENTER} justifyContent={ALIGN.CENTER}>
    <Spinner />
  </View>
);

SpinnerChapter.displayName = '⚛️ Atoms/Spinner';
