import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  textMobile: {
    paddingTop: Platform.select({
      android: '$space025',
      ios: '$space025',
      web: '$space05',
    }),
  },
  textDesktop: {
    paddingTop: '$space1',
  },
});
