import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

const isAndroid = Platform.OS === 'android';
const isIOS = Platform.OS === 'ios';

const lineMargin = () => StyleSheet.value('$space2 + $space05');

const verticalStyle = {
  step: {
    flex: 1,
  },
  track: {
    height: '100%',
  },
  line: {
    borderRightWidth: '$borderWidth1',
    borderStyle: '$borderStyle',
    minHeight: 13.5,
    flex: 1,
  },
  start: {
    marginBottom: lineMargin,
    alignSelf: 'center',
  },
  end: {
    marginTop: lineMargin,
    alignSelf: 'center',
  },
  text: {
    marginLeft: '$space5',
    paddingTop: isAndroid ? 0 : '$space05 + $space025',
  },
};

const horizontalStyle = {
  step: {
    flex: 1,
  },
  track: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  line: {
    alignSelf: 'center',
    borderTopWidth: '$borderWidth1',
    borderStyle: '$borderStyle',
    flex: 1,
  },
  start: {
    marginRight: lineMargin,
  },
  end: {
    marginLeft: lineMargin,
  },
  text: {
    marginTop: '$space6',
    paddingTop: isAndroid ? 0 : '$space05 + $space025',
  },
};

export const style = (horizontal: boolean) =>
  StyleSheet.create({
    ...(horizontal ? horizontalStyle : verticalStyle),

    circle: {
      borderWidth: '$borderWidth1',
      borderStyle: '$borderStyle',
      borderRadius: '$borderRadiusFull',
      width: '$space5 + $space05',
      height: '$space5 + $space05',
      justifyContent: 'center',
      alignItems: 'center',
    },

    lineTransparent: {
      borderColor: 'transparent',
    },

    textIndexNumber: {
      paddingTop: isAndroid ? 0 : isIOS ? '$space05' : '$space05',
    },
  });
