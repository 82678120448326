// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import { Platform, TextInput } from 'react-native';

import { getInputErrors, stylesheetFont, testID } from '@helpers';
import { styles, useBanProps, useBanStylerProps, useDevice, useStyler, useSupportedProps } from '@hooks';
import { Theme } from '@theming';

import { parseValue } from './helpers';
import { BANNED_PROPS, INPUT_TYPES, KEYBOARD_TYPES, TEXT_CONTENT_TYPES, TYPE_NUMBER } from './Input.definition';
import { style } from './Input.style';
import { TEXT_STYLE } from '../Text';

import type { InputValue } from './Input.definition';
import type { InputError } from '../../../helpers/getInputErrors';
import type { StylerProperties } from '@hooks';
import type {
  AccessibilityRole,
  NativeSyntheticEvent,
  TextInputContentSizeChangeEventData,
  TextInputProps,
} from 'react-native';

const MULTILINE_MIN_HEIGHT = 96;
const MULTILINE_MAX_HEIGHT = 172;

const clamp = (min: number, max: number, value: number) => Math.min(Math.max(min, value), max);

export interface InputProps {
  defaultValue?: string | number;
  disabled?: boolean;
  id?: string;
  label?: string;
  name?: string;
  placeholderTextColor?: string;
  required?: boolean;
  role?: AccessibilityRole;
  trimSpaces?: boolean;
  type?: INPUT_TYPES;
  value?: InputValue;
  multilineMinHeight?: number;
  multilineMaxHeight?: number;

  onError?: (error: InputError | undefined) => void;
  onChange?: (value: InputValue) => void;
}
export type InputProperties = InputProps & StylerProperties & Omit<TextInputProps, 'onChange'>;
const Input = forwardRef<TextInput, InputProperties>(
  (
    {
      disabled,
      id,
      keyboardType,
      multiline,
      multilineMinHeight = MULTILINE_MIN_HEIGHT,
      multilineMaxHeight = MULTILINE_MAX_HEIGHT,
      placeholder,
      placeholderTextColor,
      role,
      type = INPUT_TYPES.text,
      onChange,
      onError,
      autoCapitalize = 'none',
      ...others
    },
    ref,
  ) => {
    const { colorTextMedium } = Theme.get();

    const [height, setHeight] = useState(multilineMinHeight);

    const inputRef = useRef(ref);

    const isNumber = type === TYPE_NUMBER;

    useImperativeHandle(ref, () => inputRef.current);

    const handleChangeText = (next = '') => {
      if (isNumber && isNaN(next)) return;
      const value = parseValue(next, type, others);

      onError && onError(getInputErrors({ ...others, type, value }));
      onChange && onChange(isNumber ? value : value || '');
    };

    const handleOnContentSizeChange = useCallback(
      (event: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
        if (!multiline || Platform.OS === 'web') {
          return;
        }

        setHeight(clamp(multilineMinHeight, multilineMaxHeight, event.nativeEvent.contentSize.height));
      },
      [multiline, multilineMaxHeight, multilineMinHeight],
    );

    const handleOnContentSizeChangeWeb = useCallback(() => {
      if (!multiline || Platform.OS !== 'web') {
        return;
      }

      window.requestAnimationFrame(() => {
        const element = inputRef.current as unknown as HTMLTextAreaElement;

        if (!element) {
          return;
        }

        element.style.height = '0px';
        element.style.height = `${clamp(multilineMinHeight, multilineMaxHeight, element.scrollHeight)}px`;
      });
    }, [multiline, multilineMaxHeight, multilineMinHeight]);

    useLayoutEffect(handleOnContentSizeChangeWeb, [handleOnContentSizeChangeWeb, others.value, others.defaultValue]);

    return (
      <TextInput
        {...useBanProps(useBanStylerProps(others), [...BANNED_PROPS])}
        {...testID(others.testID, 'input')}
        style={styles(
          stylesheetFont({ nameSpace: '$fontInput' }).font,
          style.input,
          disabled && style.disabled,
          multiline && style.multiline,
          ...useStyler(useSupportedProps(others, Object.values(TEXT_STYLE)), Input.displayName, useDevice()).style,
          others.style,
          multiline && { height },
        )}
        accessibilityRole={Platform.OS === 'web' ? role : undefined}
        allowFontScaling={false}
        autoCapitalize={autoCapitalize}
        autoCorrect={true}
        editable={!disabled}
        keyboardType={keyboardType || KEYBOARD_TYPES[type] || KEYBOARD_TYPES.text}
        multiline={multiline}
        nativeID={id}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor || colorTextMedium}
        ref={inputRef}
        secureTextEntry={type === 'password'}
        textContentType={TEXT_CONTENT_TYPES[type]}
        onChangeText={handleChangeText}
        onContentSizeChange={handleOnContentSizeChange}
      />
    );
  },
);

Input.displayName = 'Input';

export { Input };
