import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { INPUT_TYPES, InputField, LAYOUT, Theme } from '@package';

import type { Glyphs } from '@lookiero/aurora-iconfont';

type autoCapitalize = 'characters' | 'words' | 'sentences' | 'none';
const autoCapitalizeOptions: autoCapitalize[] = ['characters', 'words', 'sentences', 'none'];

export const InputFieldChapter = () => {
  const { boolean, event, select, object, text } = useStoryteller();

  const { iconGlyphs } = Theme.get();

  return (
    <InputField
      animatedLabel={boolean('animatedLabel', true)}
      defaultValue={text('defaultValue', 'default value')}
      disabled={boolean('disabled', false)}
      error={boolean('error', false)}
      hint={text('hint', 'hint-lookiero')}
      icon={select('icon', 'eye_open', { options: Object.keys(iconGlyphs) }) as keyof Glyphs}
      id={text('id', 'id-lookiero')}
      label={text('label', 'label-lookiero')}
      name={text('name', 'name-lookiero')}
      margin={LAYOUT.XXS}
      multiLine={boolean('multiLine', false)}
      style={object('style', {})}
      type={select('type', INPUT_TYPES.text, { options: Object.keys(INPUT_TYPES) }) as INPUT_TYPES}
      // @ts-expect-error Just for better UX in Storyteller
      onBlur={() => event('onBlur')}
      onChange={(value) => event('onChange', [value])}
      onError={(error) => error && event('onError', [{ error }])}
      // @ts-expect-error Just for better UX in Storyteller
      onFocus={() => event('onFocus')}
      autoCapitalize={select('autoCapitalize', 'none', { options: autoCapitalizeOptions }) as autoCapitalize}
    />
  );
};

InputFieldChapter.displayName = '⚛️ Atoms/InputField';
