import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  spinner: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  spinnerSvg: {
    width: 30,
    height: 30,
  },
});
