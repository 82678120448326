import { Picker } from '@react-native-picker/picker';
import React, { createElement, forwardRef, useImperativeHandle } from 'react';
import { Platform } from 'react-native';

import { stylesheetFont, testID } from '@helpers';
import { styles, useBanStylerProps, useDevice, useStyler } from '@hooks';
import { Theme } from '@theming';

import { style } from './Select.style';
import { SelectIOS } from './SelectIOS';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { FC } from 'react';

export type Option = {
  name: string;
  value: string;
};
export interface SelectProps {
  disabled?: boolean;
  id?: string;
  emptyOptionText?: string;
  options?: string[] | Option[];
  value?: string | number;
  onBlur?: () => void;

  onChange?: (value: string | number | undefined) => void;
  onFocus?: () => void;
}

export type SelectProperties = SelectProps & StylerProperties;

const Select: FC<SelectProperties> = forwardRef(
  ({ disabled, emptyOptionText: emptyOption, id, options = [], value, onChange, ...others }, ref) => {
    const handleChange = (next: string | number) => {
      const nextValue = next === emptyOption ? undefined : next;

      onChange && onChange(nextValue);
      others.onBlur && others.onBlur();
    };

    const styleFont = stylesheetFont({ nameSpace: '$fontInput' }).font;
    const { colorBgBase } = Theme.get();

    const children = [];
    if (emptyOption) children.push(<Picker.Item key={emptyOption} label={emptyOption} value={emptyOption} />);
    options.forEach((option = { name: '', value: '' }) => {
      const { name, value: theValue } = typeof option === 'object' ? option : { name: option, value: option };

      children.push(<Picker.Item key={theValue} label={name} value={theValue} />);
    });

    useImperativeHandle(ref, () => {
      return Platform.OS === 'ios'
        ? ref
        : {
            focus() {
              // library is broken in version 1.x so at least do not throw an error in case of android or web
            },
            blur() {
              // library is broken in version 1.x so at least do not throw an error in case of android or web yarn start
            },
          };
    });

    return createElement(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Platform.OS === 'ios' ? SelectIOS : Picker,
      {
        ...useBanStylerProps(others),
        ...useStyler(
          {
            ...others,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style: styles(styleFont, style.container, disabled && style.disabled, others.style),
          },
          Select.displayName,
          useDevice(),
        ),
        ...testID(others.testID, 'input'),
        id,
        enabled: !disabled,
        itemStyle: [styleFont, style.item, disabled && style.disabled],
        onValueChange: handleChange,
        ref: ref,
        selectedValue: value,
        ...(Platform.OS === 'android'
          ? {
              dropdownIconColor: colorBgBase,
              dropdownIconRippleColor: colorBgBase,
            }
          : {}),
      },
      children,
    );
  },
);

Select.displayName = 'Select';

export { Select };
