import type { Screen } from '../../../../hooks/useDevice/helpers/getScreen';
import type { ScreenSize } from '../Box';

interface Units {
  [key: number]: string;
}

interface Dimensions {
  [key: number]: string;
}

const UNITS: Units = { 1: 'one', 2: 'two' };
const DIMENSIONS: Dimensions = { 2: 'half', 3: 'third', 4: 'quarter' };
const DEFAULT_BREAKPOINT: keyof ScreenSize = 'default';

export const determineSize = (size: ScreenSize, breakpoints: Screen): string => {
  let key: string;

  if (typeof size === 'object') {
    const breakpoint = Object.keys(breakpoints).find((aKey) => breakpoints[aKey as keyof Screen] === true);

    key = size[breakpoint as keyof ScreenSize] || size[DEFAULT_BREAKPOINT] || '';
  } else {
    key = size || '';
  }

  const [unit, dimension] = key.split('/');
  const parsedUnit = parseInt(unit, 10);
  const parsedDimension = parseInt(dimension, 10);

  return `${UNITS[parsedUnit]}${DIMENSIONS[parsedDimension]}`;
};
