import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  title: {
    textAlign: 'left',
    marginBottom: '$space3',
  },

  item: {
    borderStyle: '$borderStyle',
    borderRadius: '$borderRadiusFull',
    height: '$space16 - $space2',
    width: '$space16 - $space2',
  },

  itemSmall: {
    width: '$space5',
    height: '$space5',
    borderWidth: '$borderWidth1',
    borderStyle: '$borderStyle',
    borderRadius: '$borderRadiusFull',
  },

  value: {
    letterSpacing: 0,
    paddingTop: '$space1',
  },

  valueSmall: {
    marginBottom: '$space1',
    paddingTop: 0,
  },

  captionContainer: {
    marginTop: '$space3',
  },
});
