import React, { createContext, createElement, useContext, useMemo, useReducer } from 'react';
import { SafeAreaView } from 'react-native';

import { POSITION, SIZE, styles, useDevice } from '@hooks';
import { NOTIFICATION_VARIANT } from '@molecules';
import { Theme } from '@theming';

import { DEFAULT_STACK } from './Stack.definition';
import { STACK_REDUCER, StackReducer } from './Stack.reducer';
import { style } from './Stack.style';
import { View } from '../../components/primitives/View';

import type { NotificationProperties } from '../../components/molecules/Notification/Notification';
import type { Component, FC, FunctionComponent, ReactNode } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';

interface StackContextProperties {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  show: (id: string, component: Component | FunctionComponent, props: any) => void;

  success: (id: string, component: Component | FunctionComponent, props: NotificationProperties) => void;

  critical: (id: string, component: Component | FunctionComponent, props: NotificationProperties) => void;

  warning: (id: string, component: Component | FunctionComponent, props: NotificationProperties) => void;

  neutral: (id: string, component: Component | FunctionComponent, props: NotificationProperties) => void;

  promo: (id: string, component: Component | FunctionComponent, props: NotificationProperties) => void;

  hide: (id: string) => void;
  wipe: () => void;
}

const StackContext = createContext<StackContextProperties>(DEFAULT_STACK);

const useStack = (): StackContextProperties => useContext(StackContext);

interface StackProviderProps {
  children: ReactNode;
}

export type StackProviderProperties = {
  style?: StyleProp<ViewStyle>;
} & StackProviderProps;

const StackProvider: FC<StackProviderProperties> = ({ children, ...others }) => {
  const { screen } = useDevice();
  const [state, dispatch] = useReducer(StackReducer, {});

  const { motionExpand } = Theme.get();

  const value = useMemo(
    (): StackContextProperties => ({
      show: (id, component, props) => {
        // @ts-expect-error - We need better typing here
        dispatch({ type: STACK_REDUCER.MOUNT, component: component, id: id, props: props });
        setTimeout(() => {
          dispatch({ type: STACK_REDUCER.SHOW, id: id });
        }, 0);
      },
      success: (id, component, props) => {
        value.show(id, component, { ...props, variant: NOTIFICATION_VARIANT.SUCCESS });
      },
      critical: (id, component, props) => {
        value.show(id, component, { ...props, variant: NOTIFICATION_VARIANT.CRITICAL });
      },
      warning: (id, component, props) => {
        value.show(id, component, { ...props, variant: NOTIFICATION_VARIANT.WARNING });
      },
      neutral: (id, component, props) => {
        value.show(id, component, { ...props, variant: NOTIFICATION_VARIANT.NEUTRAL });
      },
      promo: (id, component, props) => {
        value.show(id, component, { ...props, variant: NOTIFICATION_VARIANT.PROMO });
      },
      hide: (id) => {
        dispatch({ type: STACK_REDUCER.HIDE, id: id });
        setTimeout(() => {
          dispatch({ type: STACK_REDUCER.UNMOUNT, id: id });
        }, motionExpand);
      },
      wipe: () => {
        dispatch({ type: STACK_REDUCER.UNMOUNT_ALL });
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  return (
    <StackContext.Provider value={value}>
      <View
        layer={SIZE.XL}
        position={POSITION.FIXED}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style={styles(style.stack, screen.L && style.maxWidth, others.style)}
      >
        <SafeAreaView>
          {Object.keys(state).map((key, index) => {
            const { component, props } = state[key];

            const { onClose } = props;

            return createElement(component, {
              ...props,
              key,
              // @ts-expect-error - We need this zIndex
              zIndex: index,
              onClose: () => {
                value.hide(key);

                onClose && onClose();
              },
            });
          })}
        </SafeAreaView>
      </View>
      {children}
    </StackContext.Provider>
  );
};

StackProvider.displayName = 'AuroraStackProvider';

export { useStack, StackContext, StackProvider };
