import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { IMAGE_RESIZE_MODES, Image } from '@package';

export const ImageChapter = () => {
  const { boolean, event, object, select, text } = useStoryteller();

  return (
    <Image
      alt={text('alt', 'lookiero-alt')}
      autoSize={boolean('autoSize', false)}
      // @ts-expect-error Just for better UX in Storyteller
      resizeMode={select('resizeMode', IMAGE_RESIZE_MODES.contain, { options: Object.keys(IMAGE_RESIZE_MODES) })}
      src={text('src', 'https://via.placeholder.com/256/?text=image')}
      srcSet={text(
        'srcSet',
        'https://via.placeholder.com/256/?text=image 1x, https://via.placeholder.com/512/?text=image 2x',
        { multiLine: true },
      )}
      style={object('style', { height: 128, width: 128 })}
      // @ts-expect-error Just for better UX in Storyteller
      onError={(error) => error && event('onError', error)}
    />
  );
};

ImageChapter.displayName = '🐵 Primitives/Image';
