import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },

  input: {
    marginRight: '$space4',
  },

  noVisibleSwitch: {
    justifyContent: 'center',
  },

  buttonChecked: {
    borderWidth: '$borderWidth2',
    padding: () => StyleSheet.value('$space4 - $space025'),
  },

  button: {
    borderStyle: '$borderStyle',
    borderWidth: '$borderWidth1',
    borderRadius: '$borderRadius3',
    padding: '$space4',
    alignSelf: 'stretch',
  },

  card: {
    borderRadius: '$borderRadius2',
    paddingHorizontal: '$space4',
    paddingVertical: '$space6',
  },

  cardSmall: {
    paddingVertical: '$space2',
  },

  pill: {
    borderRadius: '$borderRadiusFull',
    paddingHorizontal: '$space6',
    paddingVertical: '$space4',
  },

  pillSmall: {
    paddingHorizontal: '$space4',
    paddingVertical: '$space2',
  },
});
