// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useStoryteller } from '@lookiero/storyteller';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { SafeAreaView } from 'react-native';

import { BUTTON_VARIANT, Button, InputField, ScrollView, Text } from '@package';

export const InputFieldFocusChapter = () => {
  const { event } = useStoryteller();
  const nameRef = createRef();
  const telRef = createRef();
  const passwordRef = createRef();
  const dateRef = createRef();
  const measurementsRef = createRef();
  const yesNoRef = createRef();

  const [currentState, setCurrentState] = useState(-1);
  const getRefs = useCallback(
    () => [nameRef, telRef, passwordRef, dateRef, measurementsRef, yesNoRef],
    [nameRef, telRef, passwordRef, dateRef, measurementsRef, yesNoRef],
  );

  useEffect(() => {
    if (currentState >= 0) {
      if (currentState < getRefs().length) {
        getRefs()[currentState].current.focus();
      } else {
        setCurrentState(0);
      }
    }
  }, [currentState, getRefs]);

  return (
    <SafeAreaView>
      {/* eslint-disable-next-line react-native/no-inline-styles*/}
      <ScrollView style={{ paddingHorizontal: 32, maxWidth: '100%', paddingTop: 32, paddingBottom: 32 }}>
        <Button
          onPress={() => setCurrentState(currentState + 1)}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ marginBottom: 32 }}
          variant={BUTTON_VARIANT.SECONDARY}
        >
          {'Press to Focus next'}
        </Button>
        <InputField
          id={'name'}
          label={'name'}
          name={'name'}
          ref={nameRef}
          onBlur={() => event('onBlur name')}
          onChange={(value) => event('onChange name', value)}
          onFocus={() => {
            setCurrentState(0);
            event('onFocus name');
          }}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ marginBottom: 32 }}
        />
        <InputField
          id={'telephone'}
          label={'telephone'}
          name={'telephone'}
          ref={telRef}
          type={'tel'}
          prefix={'+34'}
          onBlur={() => event('onBlur tel')}
          onChange={(value) => event('onChange tel', value)}
          onFocus={() => {
            setCurrentState(1);
            event('onFocus tel');
          }}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ marginBottom: 32 }}
        />
        <InputField
          id={'password'}
          label={'password'}
          name={'password'}
          ref={passwordRef}
          type={'password'}
          onBlur={() => event('onBlur password')}
          onChange={(value) => event('onChange password', value)}
          onFocus={() => {
            setCurrentState(2);
            event('onFocus password');
          }}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ marginBottom: 32 }}
        />
        <InputField
          id={'measurements'}
          label={'measurements'}
          name={'measurements'}
          ref={measurementsRef}
          fieldset={{ ft: 'number', in: 'number' }}
          onBlur={() => event('onBlur measurements')}
          onChange={(value) => event('onChange measurements', value)}
          onFocus={() => {
            setCurrentState(4);
            event('onFocus measurements');
          }}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ marginBottom: 32 }}
        />
        <Text>Select focus only works on iOS native device, web and android are broken</Text>
        <InputField
          id={'yesNo'}
          label={'yes no question'}
          name={'yesNo'}
          ref={yesNoRef}
          options={[
            { name: 'Yes', value: true },
            { name: 'No', value: false },
          ]}
          onBlur={() => event('onBlur yesNo')}
          onChange={(value) => event('onChange yesNo', value)}
          onFocus={() => {
            setCurrentState(5);
            event('onFocus yesNo');
          }}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ marginBottom: 32 }}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

InputFieldFocusChapter.displayName = '⚛️ Atoms/InputField (Focus)';
