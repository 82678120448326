import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Calendar } from '@package';

const today = new Date().toISOString().slice(0, 10).replace(/-/g, '/');
const [year, month] = today.split('/');
const disabledDates = [
  `${year}/${month}/4`,
  `${year}/${month}/8`,
  `${year}/${month}/12`,
  `${year}/${month}/16`,
  `${year}/${month}/20`,
  `${year}/${month}/24`,
  `${year}/${month}/28`,
].filter((date) => date !== today);

export const CalendarChapter = () => {
  const { array, boolean, event, object, select, text } = useStoryteller();

  return (
    <Calendar
      disabledDates={array('disabledDates', disabledDates) as string[]}
      disabledPast={boolean('disabledPast', false)}
      disabledToday={boolean('disabledToday', false)}
      from={text('from', `${year}/1/15`)}
      locale={select('locale', 'es-ES', { options: ['es-ES', 'fr-FR', 'pt-PT', 'de-DE', 'it-IT'] })}
      style={object('style', {})}
      to={text('to', `${parseInt(year, 10) + 1}/1/15`)}
      value={text('value', today)}
      onChange={(e) => event('onChange', [e])}
    />
  );
};

CalendarChapter.displayName = '✳️ Molecules/Calendar';
