import React, { Fragment, forwardRef, useCallback, useEffect, useState } from 'react';
import { Platform, StatusBar } from 'react-native';

import { Box, ButtonIcon, Layout, Overlay } from '@atoms';
import { testID } from '@helpers';
import {
  ALIGN,
  COLOR,
  FLEX_DIRECTION,
  POINTER,
  POSITION,
  SIZE,
  SPACE,
  styles,
  useBack,
  useDevice,
  useSwipe,
} from '@hooks';
import { MOTION_TIMING, MOTION_TYPES, Motion, ScrollView, TEXT_ROLES, Text, VIEW_ROLES, View } from '@primitives';
import { Theme } from '@theming';

import { style } from './Modal.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ScreenSize } from '../../atoms/Box/Box';
import type { ViewProperties } from '../../primitives/View/View';
import type { ReactNode } from 'react';
import type { ScrollView as BaseScrollView, StyleProp, ViewComponent, ViewStyle } from 'react-native';

const MIN_DELTA_TO_CLOSE = 80;

export interface ModalProps {
  color?: string;
  contentStyle?: StyleProp<ViewStyle>;
  description?: ReactNode;
  fullHeight?: boolean;
  fullWidth?: boolean;
  headerStyle?: StyleProp<ViewStyle>;
  isVisible?: boolean;
  nativeID?: string;
  overlayClose?: boolean;
  scrollToEnd?: boolean;
  scrollable?: boolean;
  size?: ScreenSize;
  swipeable?: boolean;
  title?: string;
  onClose?: () => void;
  onHardwareBackPress?: () => void;
  scrollRef?: React.Ref<BaseScrollView>;
}

export type ModalProperties = ModalProps & StylerProperties & ViewProperties;

const Modal = forwardRef<ViewComponent, ModalProperties>(
  (
    {
      children,
      color = COLOR.BASE,
      contentStyle,
      description,
      fullHeight = false,
      fullWidth = false,
      headerStyle,
      isVisible = false,
      overlayClose = true,
      nativeID,
      scrollToEnd = false,
      scrollable = false,
      size = { M: '2/3', L: '1/3' },
      swipeable = false,
      title,
      onClose,
      onHardwareBackPress,
      scrollRef,
      ...others
    },
    ref,
  ) => {
    const { screen } = useDevice();
    const handleSwipe = useSwipe({
      onSwiping: ({ deltaY, down }: { deltaY: number; down: boolean }) => {
        const nextTop = Math.abs(parseInt(`${deltaY}`, 10));

        if (!down || nextTop === 0) return;
        setTop(nextTop);
        setSwiping(true);
      },
      // @ts-expect-error We should review this
      onSwiped: ({ deltaY, down }: { deltaY: number; down: boolean }) => {
        if (!down) return;

        setTop(0);
        if (onClose && Math.abs(deltaY) >= MIN_DELTA_TO_CLOSE) onClose();
        else setTimeout(() => setSwiping(false), motionCollapse);
      },
    });

    useBack(
      useCallback(() => {
        if (isVisible) {
          if (onHardwareBackPress) onHardwareBackPress();
          else if (onClose) onClose();
        }

        return isVisible;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isVisible, onClose]),
    );

    const { motionCollapse, space10 } = Theme.get();

    const [top, setTop] = useState(0);
    const [swiping, setSwiping] = useState(false);

    useEffect(() => {
      setSwiping(false);
      if (isVisible) setTop(0);
    }, [isVisible]);

    const isSwipeable = screen.S && swipeable;
    const Scroller = scrollable ? ScrollView : Fragment;

    const maxHeight = Platform.OS === 'web' ? { maxHeight: screen.height - (!screen.S ? space10 * 2 : 0) } : {};

    return (
      <View
        {...others}
        {...(!isVisible
          ? {
              accessibilityElementsHidden: true,
              importantForAccessibility: 'no-hide-descendants',
              pointer: POINTER.NONE,
            }
          : undefined)}
        alignItems={screen.S ? ALIGN.END : ALIGN.CENTER}
        flexDirection={FLEX_DIRECTION.ROW}
        layer={SIZE.L}
        nativeID={nativeID ? `${nativeID}-container` : undefined}
        position={POSITION.FIXED}
        role={VIEW_ROLES.aside}
        style={styles(style.modal, others.style)}
        wide
      >
        <Overlay
          isVisible={isVisible}
          nativeID={nativeID ? `${nativeID}-overlay` : undefined}
          onPress={onClose && overlayClose ? onClose : undefined}
        />
        <Layout
          fullWidth={screen.S}
          justifyContent={ALIGN.CENTER}
          layer={SIZE.L}
          nativeID={nativeID ? `${nativeID}` : undefined}
          style={[maxHeight, fullHeight && style.fullHeight]}
        >
          <Motion
            disabled={top !== 0}
            layout={screen}
            position={POSITION.RELATIVE}
            role={VIEW_ROLES.section}
            style={fullHeight ? style.fullHeight : undefined}
            timing={isVisible && ((isVisible && screen.S && swiping) || !screen.S) ? MOTION_TIMING.SPRING : undefined}
            type={isVisible ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE}
            value={
              screen.S
                ? { translateY: isVisible ? top || '0%' : '100%' }
                : { opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.9 }
            }
            wide
          >
            <Box align={ALIGN.CENTER} size={size} style={[fullHeight && style.fullHeight]}>
              {fullHeight && Platform.OS === 'android' && <StatusBar translucent={false} />}

              <View
                ref={ref}
                style={[
                  style.container,
                  !screen.S && style.containerBorderBottomRadius,
                  fullHeight && style.fullHeight,
                  fullWidth && style.fullWidth,
                  contentStyle,
                ]}
              >
                {onClose && (
                  <View
                    {...(isSwipeable && isVisible ? handleSwipe : undefined)}
                    justifyContent={isSwipeable ? ALIGN.CENTER : ALIGN.AUTO}
                    wide={!isSwipeable}
                    style={[headerStyle, style.buttonContainer, fullWidth && style.buttonContainerFullWidth]}
                  >
                    <ButtonIcon
                      backgroundColor={color}
                      color={COLOR.ICON}
                      name={isSwipeable ? 'arrow_down' : 'close'}
                      accessibilityLabel="close"
                      onPress={onClose}
                      testID={nativeID ? `${nativeID}-close` : undefined}
                      small
                    />
                  </View>
                )}
                <Scroller {...(scrollable ? { scrollToEnd, ref: scrollRef } : {})}>
                  {(title || description) && (
                    <View role="header" marginBottom={SPACE.SPACE_6}>
                      {title && (
                        <Text
                          color={COLOR.TEXT}
                          heading
                          level={3}
                          role={TEXT_ROLES.h2}
                          {...testID(others.testID, 'title')}
                        >
                          {title}
                        </Text>
                      )}
                      {description && (
                        <Text color={COLOR.TEXT_MEDIUM} marginTop={title ? SPACE.SPACE_05 : undefined}>
                          {description}
                        </Text>
                      )}
                    </View>
                  )}
                  {children}
                </Scroller>
              </View>
            </Box>
          </Motion>
        </Layout>
      </View>
    );
  },
);

Modal.displayName = 'Modal';

export { Modal };
