const BANNED_PROPS = ['autoComplete', 'id', 'required', 'style'];

const TYPE_NUMBER = 'number';

enum INPUT_TYPES {
  text = 'text',
  password = 'password',
  email = 'email',
  number = 'number',
  search = 'search',
  tel = 'tel',
  time = 'time', // TODO: Deprecate type time
  url = 'url',
}

const KEYBOARD_TYPES = {
  email: 'email-address',
  number: 'numeric',
  tel: 'number-pad',
  text: 'default',
  url: 'url',
};

enum TEXT_CONTENT_TYPES {
  email = 'emailAddress',
  password = 'password',
  tel = 'telephoneNumber',
  url = 'URL',
}

export type InputValue = number | string | Record<string, number | string | undefined>;

export { BANNED_PROPS, INPUT_TYPES, KEYBOARD_TYPES, TYPE_NUMBER, TEXT_CONTENT_TYPES };
