// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef } from 'react';

import { Input, Select } from '@primitives';

import { FieldSet, Password, Phone } from './variants';

import type { Fields } from './variants/Fieldset/Fieldset';
import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { InputProperties } from '../../primitives/Input/Input';
import type { InputValue } from '../../primitives/Input/Input.definition';
import type { Option } from '../../primitives/Select/Select';
import type { FC } from 'react';

export interface HOCProps {
  defaultValue?: InputValue;
  disabled?: boolean;
  error?: boolean;
  focus?: boolean;
  multiLine?: boolean;
  options?: Option[] | string[];
  fieldset?: Fields;
  type?: string;
  value?: InputValue;
}

export type HOCProperties = HOCProps & StylerProperties & InputProperties;

type SelectValue = string | number;

export const HOC: FC<HOCProperties> = forwardRef(({ multiLine, options, fieldset, ...others }, ref) => {
  const { defaultValue, type, value } = others;

  if (type === 'password') return <Password {...others} ref={ref} />;
  else if (type === 'tel') return <Phone {...others} ref={ref} />;
  else if (options)
    return (
      <Select
        {...others}
        color={undefined}
        options={options}
        value={(value as SelectValue) || (defaultValue as SelectValue)}
        ref={ref}
      />
    );
  else if (fieldset) return <FieldSet {...others} fields={fieldset} ref={ref} />;

  return <Input {...others} color={undefined} multiline={multiLine} ref={ref} />;
});
