// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { ScrollView as BaseScrollView, Platform } from 'react-native';

import { IS_JEST, testID } from '@helpers';
import { styles, useBanProps, useBanStylerProps, useDevice, useStyler, useSupportedProps } from '@hooks';

import { BANNED_PROPS, STYLE_BANNED_PROPS } from './ScrollView.definition';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { VIEW_ROLES } from '../View';
import type {
  AccessibilityRole,
  ScrollViewProps as BaseScrollViewProps,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from 'react-native';

export interface ScrollPayload {
  X: number;
  Y: number;
  percentX: number;
  percentY: number;
}

export interface ScrollViewProps {
  animated?: boolean;
  height?: number;
  onScroll?: (scrollPayload: ScrollPayload, e: NativeSyntheticEvent<NativeScrollEvent>) => void;
  role?: AccessibilityRole | VIEW_ROLES;
  scrollIndicator?: boolean;
  scrollTo?: number;
  scrollToEnd?: boolean;
  snapInterval?: number;
  width?: number;
}

export type ScrollViewProperties = ScrollViewProps & StylerProperties & Omit<BaseScrollViewProps, 'onScroll'>;

const ScrollView = forwardRef<BaseScrollView, ScrollViewProperties>(
  (
    {
      animated = true,
      children,
      height,
      scrollIndicator = false,
      horizontal,
      role,
      scrollEventThrottle = Platform.OS === 'ios' ? 0 : undefined,
      scrollTo,
      scrollToEnd,
      snapInterval,
      width,
      onScroll,
      ...others
    },
    theForwardRef,
  ) => {
    const ref = useRef();
    useImperativeHandle(theForwardRef, () => ref.current);

    useEffect(() => {
      const { current } = ref;

      if (current && (scrollTo !== undefined || scrollToEnd)) {
        if (IS_JEST && Platform.OS === 'web') return;

        if (scrollToEnd) current.scrollToEnd({ animated });
        else current.scrollTo({ [horizontal ? 'x' : 'y']: scrollTo, animated });
      }
    }, [animated, horizontal, scrollTo, scrollToEnd, ref]);

    const handleScroll = (event = {}) => {
      const {
        layoutMeasurement: { height: offsetHeight, width: offsetWidth } = {},
        contentOffset: { x: X, y: Y } = {},
        contentSize: { height: scrollHeight, width: scrollWidth } = {},
      } = event.nativeEvent || {};

      const percentX = X > 0 ? Math.floor((X * 100) / (scrollWidth - offsetWidth)) : 0;
      const percentY = Y > 0 ? Math.floor((Y * 100) / (scrollHeight - offsetHeight)) : 0;

      if (
        snapInterval === undefined ||
        percentX === 100 ||
        percentY === 100 ||
        parseInt((horizontal ? X : Y) % snapInterval, 10) === 0
      )
        onScroll({ X, Y, percentX, percentY }, event);
    };

    return (
      <BaseScrollView
        {...useBanProps(useBanStylerProps(others), BANNED_PROPS)}
        {...useStyler(
          {
            ...useBanProps(others, STYLE_BANNED_PROPS),
            style: styles({ height, maxHeight: height, maxWidth: width, width }, others.style),
          },
          ScrollView.displayName,
          useDevice(),
        )}
        {...testID(others.testID)}
        {...(snapInterval
          ? {
              decelerationRate: 'fast',
              pagingEnabled: Platform.OS === 'web' || (Platform.OS === 'android' && horizontal),
              snapToAlignment: 'start',
              snapToInterval: snapInterval,
            }
          : {})}
        contentContainerStyle={
          useStyler(
            { ...useSupportedProps(others, STYLE_BANNED_PROPS), style: others.contentContainerStyle },
            ScrollView.displayName,
            useDevice(),
          ).style
        }
        accessibilityRole={Platform.OS === 'web' ? role : undefined}
        horizontal={horizontal}
        ref={ref}
        scrollEventThrottle={scrollEventThrottle}
        showsHorizontalScrollIndicator={scrollIndicator}
        showsVerticalScrollIndicator={scrollIndicator}
        onScroll={onScroll ? handleScroll : undefined}
      >
        {children}
      </BaseScrollView>
    );
  },
);

ScrollView.displayName = 'ScrollView';

export { ScrollView };
