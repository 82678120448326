// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState } from 'react';
import { StyleSheet } from 'react-native';

import { ButtonIcon, InputField } from '@atoms';
import { ALIGN, FLEX_DIRECTION } from '@hooks';
import { ScrollView, Text, View } from '@primitives';
import { Theme } from '@theming';

import type { ThemeDefinition } from '../../src/theming/Theme.definition';
import type { FC, ReactNode } from 'react';

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
    display: 'flex',
    flexDirection: 'column',
    paddingHorizontal: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    paddingVertical: 4,
  },
  evenRow: {
    backgroundColor: '#e7e7e7',
  },
  tokenValue: {
    flex: 1,
    textAlign: 'right',
    textWrap: 'nowrap',
  },
  clearButton: {
    paddingVertical: 17,
  },
  colorBox: {
    height: 20,
    width: 20,
    marginLeft: 8,
    borderWidth: 1,
    borderColor: '$colorIconLight',
  },
});
export const TokensChapter: FC = () => {
  const theme = Theme.get();

  const [filter, setFilter] = useState<Partial<ThemeDefinition>>(theme);
  const [filterValue, setFilterValue] = useState<string>('');
  const handleChange = (value: string) => {
    setFilter(
      Object.fromEntries(
        Object.entries(theme).filter(([k, v]) => k.indexOf(value) !== -1 || String(v).indexOf(value) !== -1),
      ),
    );
    setFilterValue(value);
  };

  return (
    <View style={styles.container}>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <View style={{ flexDirection: 'row', paddingBottom: 24 }}>
        <InputField
          label={'Search key or value'}
          name={'search'}
          onChange={handleChange}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ flex: 1 }}
          value={filterValue}
        />
        <ButtonIcon
          name={'trash'}
          onPress={() => {
            setFilter(theme);
            setFilterValue('');
          }}
          style={styles.clearButton}
        />
      </View>

      <ScrollView scrollIndicator>
        {Object.keys(filter).map((k, i) => (
          <View key={k} style={[styles.row, i % 2 === 0 && styles.evenRow]}>
            {/* eslint-disable-next-line react-native/no-inline-styles */}
            <Text detail level={1} selectable style={{ flex: 1 }}>
              {k}
            </Text>

            <Text detail level={1} selectable style={styles.tokenValue}>
              {typeof theme[k] !== 'object' ? printStringValue(k, theme[k]) : JSON.stringify(theme[k], undefined, 0)}
            </Text>
          </View>
        ))}
      </ScrollView>
    </View>
  );
};

const printStringValue = (key: string, value: string): ReactNode => {
  const strValue = String(value);
  if (!key.includes('color')) {
    return strValue;
  }
  return (
    <View flexDirection={FLEX_DIRECTION.ROW} justifyContent={ALIGN.END}>
      <Text style={styles.tokenValue}>{value}</Text>
      <View style={[styles.colorBox, { backgroundColor: strValue }]} />
    </View>
  );
};

TokensChapter.displayName = '🪝 Theming/Tokens';
