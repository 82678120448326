/* eslint-disable aurora-rebrand/deprecated-tokens */
import { Easing, Platform } from 'react-native';

import type { ThemeDefinition } from './Theme.definition';

const isAndroid = Platform.OS === 'android';
const isWeb = Platform.OS === 'web';

export const DefaultTheme: ThemeDefinition = {
  $theme: 'lookiero',

  /* NEW BORDER TOKENS */
  $borderRadius1: 2,
  $borderRadius2: 4,
  $borderRadius3: 8,
  $borderRadius4: 16,
  $borderRadius5: 24,
  $borderRadiusFull: 9999,
  $borderWidth05: 0.5,
  $borderWidth1: 1,
  $borderWidth2: 2,

  /* BORDER */
  $borderRadius: 0,
  $borderSize: 1,
  $borderStyle: 'solid', // NO DEPRECATED

  /* NEW COLOR TOKENS */
  $colorBgActionPrimaryActive: '#0C0A0A',
  $colorBgActionPrimaryDisabled: '#DAD8D8',
  $colorBgActionPrimaryHover: '#5B5757',
  $colorBgActionPrimaryPressed: '#716A6B',
  $colorBgActionPrimaryActiveStart: '#0C0A0A',
  $colorBgActionPrimaryActiveEnd: '#0C0A0A',
  $colorBgActionSecondaryActive: '#FFFFFF',
  $colorBgActionSecondaryDisabled: '#DAD8D8',
  $colorBgActionSecondaryHover: '#DAD8D8',
  $colorBgActionSecondaryPressed: '#B7B3B3',
  $colorBgBase: '#FFFFFF',
  $colorBgBaseTransparent: 'rgba(255, 255, 255, 0)',
  $colorBgBurnt: '#913D3D',
  $colorBgError: '#F45545',
  $colorBgErrorLight: '#FFE4E1',
  $colorBgInteractiveActive: '#F8F7F7',
  $colorBgInteractiveDisabled: '#F8F7F7',
  $colorBgInteractiveHover: '#DAD8D8',
  $colorBgInteractiveSelected: '#F4A584',
  $colorBgOlive: '#5D734E',
  $colorBgOpacity: 'rgba(12, 10, 10, 0.7)',
  $colorBgOpacityLight: 'rgba(12, 10, 10, 0.3)',
  $colorBgPrimary: '#0C0A0A',
  $colorBgPrimaryLight: '#F8F7F7',
  $colorBgPrimaryMedium: '#B7B3B3',
  $colorBgPrimaryMediumLight: '#DAD8D8',
  $colorBgPromotion: '#D6F1E9',
  $colorBgRose: '#C66666',
  $colorBgSuccess: '#3DA490',
  $colorBgSurface: '#F1E4DB',
  $colorBgSurfaceLight: '#FAF4F2',
  $colorBgWarning: '#E8CC42',
  $colorBgWarningLight: '#F9F5CC',

  $colorBorderActionSecondary: '#0C0A0A',
  $colorBorderInput: '#DAD8D8',
  $colorBorderInputError: '#F45545',
  $colorBorderInputFocus: '#0C0A0A',
  $colorBorderInteractive: '#DAD8D8',
  $colorBorderInteractiveSelected: '#0C0A0A',

  $colorIcon: '#0C0A0A',
  $colorIconDisabled: '#DAD8D8',
  $colorIconError: '#F45545',
  $colorIconHighlight: '#F4A584',
  $colorIconLight: '#999495',
  $colorIconOnPrimary: '#FFFFFF',
  $colorIconOpacity: 'rgba(12, 10, 10, 0.7)',
  $colorIconOpacityLight: 'rgba(12, 10, 10, 0.3)',
  $colorIconPromotion: '#256056',
  $colorIconSuccess: '#3DA490',
  $colorIconWarning: '#E8CC42',

  $colorText: '#0C0A0A',
  $colorTextActionDisabled: '#999495',
  $colorTextActionPrimary: '#FFFFFF',
  $colorTextActionSecondary: '#0C0C0A',
  $colorTextDisabled: '#DAD8D8',
  $colorTextError: '#F45545',
  $colorTextLight: '#FFFFFF',
  $colorTextMedium: '#837C7C',
  $colorTextMediumLight: '#DAD8D8',
  $colorTextOpacity: 'rgba(12, 10, 10, 0.7)',
  $colorTextOpacityLight: 'rgba(12, 10, 10, 0.3)',
  $colorTextPromotion: '#256056',

  // TODO: Deprecate in the next release
  /* COLOR */
  $colorBase: 'white',
  $colorContent: 'black',
  $colorAccent: '#ffd9c7',
  $colorPrimary: '#ff9081',
  $colorAlert: '#ffdb7c',
  $colorInfo: '#f2e8d9',
  $colorGrayscaleXL: '#7a7a7a',
  $colorGrayscaleL: '#ababab',
  $colorGrayscaleM: '#cfcfcf',
  $colorGrayscaleS: '#e7e7e7',
  $colorOverlay: 'rgba(231, 231, 231, 0.8)',
  $colorTouchable: 'rgba(255, 255, 255, 0.15)',

  // TODO: Deprecate in the next release
  /* ELEVATION */
  $elevationOffset: { width: 0, height: 8 },
  $elevationRadius: 18,
  $elevationOpacityS: 0.075,
  $elevationOpacityM: 0.15,
  $elevationColor: 'rgb(0, 0, 0)',

  /* SHADOW and ELEVATION */
  $shadowOffset: { width: 0, height: 0 },
  $shadowRadius: 32,
  $shadowOpacityS: 0.075,
  $shadowOpacityM: 1,
  $shadowColor: isAndroid ? 'rgba(12, 10, 10, 1)' : 'rgba(12, 10, 10, 0.16)',
  $shadowElevation: 16,

  /* LAYER (zIndex) */
  $elevationLayerXS: 1,
  $elevationLayerS: 2,
  $elevationLayerM: 3,
  $elevationLayerL: 4,
  $elevationLayerXL: 5,

  /* FONT */
  $fontMap: {
    Heading: 'AreaInktrap-Semibold',
    Body: 'AreaNormal-Semibold',
    Action: 'AreaNormal-Extrabold',
    Detail: 'AreaNormal-Semibold',
    DetailBold: 'AreaNormal-Extrabold',
  },

  $fontHeading: 'Heading',
  $fontHeadingStyle1: 'normal',
  $fontHeadingVariant1: ['normal'],
  $fontHeadingWeight1: 'normal',
  $fontHeadingSize1: 68,
  $fontHeadingHeight1: 78,
  $fontHeadingPaddingTop1: 0,
  $fontHeadingPaddingRight1: 0,
  $fontHeadingPaddingBottom1: 0,
  $fontHeadingPaddingLeft1: 0,
  $fontHeadingLetterSpacing1: -1,
  $fontHeadingStyle2: 'normal',
  $fontHeadingVariant2: ['normal'],
  $fontHeadingWeight2: 'normal',
  $fontHeadingSize2: 42,
  $fontHeadingHeight2: 49,
  $fontHeadingPaddingTop2: 0,
  $fontHeadingPaddingRight2: 0,
  $fontHeadingPaddingBottom2: 0,
  $fontHeadingPaddingLeft2: 0,
  $fontHeadingLetterSpacing2: -1,
  $fontHeadingStyle3: 'normal',
  $fontHeadingVariant3: ['normal'],
  $fontHeadingWeight3: 'normal',
  $fontHeadingSize3: 31,
  $fontHeadingHeight3: 36,
  $fontHeadingPaddingTop3: 0,
  $fontHeadingPaddingRight3: 0,
  $fontHeadingPaddingBottom3: 0,
  $fontHeadingPaddingLeft3: 0,
  $fontHeadingLetterSpacing3: -1,

  $fontBody: 'Body',
  $fontBodyStyle1: 'normal',
  $fontBodyVariant1: ['normal'],
  $fontBodyWeight1: '300',
  $fontBodySize1: 22,
  $fontBodyHeight1: 26,
  $fontBodyPaddingTop1: 0,
  $fontBodyPaddingRight1: 0,
  $fontBodyPaddingBottom1: 0,
  $fontBodyPaddingLeft1: 0,
  $fontBodyLetterSpacing1: -0.2,
  $fontBodyStyle2: 'normal',
  $fontBodyVariant2: ['normal'],
  $fontBodyWeight2: '300',
  $fontBodySize2: 18,
  $fontBodyHeight2: 22,
  $fontBodyPaddingTop2: 0,
  $fontBodyPaddingRight2: 0,
  $fontBodyPaddingBottom2: 0,
  $fontBodyPaddingLeft2: 0,
  $fontBodyLetterSpacing2: -0.2,
  $fontBodyStyle3: 'normal',
  $fontBodyVariant3: ['normal'],
  $fontBodyWeight3: '300',
  $fontBodySize3: 15,
  $fontBodyHeight3: 20,
  $fontBodyPaddingTop3: 0,
  $fontBodyPaddingRight3: 0,
  $fontBodyPaddingBottom3: 0,
  $fontBodyPaddingLeft3: 0,
  $fontBodyLetterSpacing3: -0.2,

  $fontAction: 'Action',
  $fontActionStyle1: 'normal',
  $fontActionVariant1: ['normal'],
  $fontActionWeight1: 'normal',
  $fontActionSize1: 22,
  $fontActionHeight1: 26,
  $fontActionPaddingTop1: 0,
  $fontActionPaddingRight1: 0,
  $fontActionPaddingBottom1: 0,
  $fontActionPaddingLeft1: 0,
  $fontActionLetterSpacing1: -0.2,
  $fontActionStyle2: 'normal',
  $fontActionVariant2: ['normal'],
  $fontActionWeight2: 'normal',
  $fontActionSize2: 18,
  $fontActionHeight2: 22,
  $fontActionPaddingTop2: 0,
  $fontActionPaddingRight2: 0,
  $fontActionPaddingBottom2: 0,
  $fontActionPaddingLeft2: 0,
  $fontActionLetterSpacing2: -0.2,
  $fontActionStyle3: 'normal',
  $fontActionVariant3: ['normal'],
  $fontActionWeight3: 'normal',
  $fontActionSize3: 15,
  $fontActionHeight3: 20,
  $fontActionPaddingTop3: 0,
  $fontActionPaddingRight3: 0,
  $fontActionPaddingBottom3: 0,
  $fontActionPaddingLeft3: 0,
  $fontActionLetterSpacing3: -0.2,

  $fontDetail: 'Detail',
  $fontDetailStyle1: 'normal',
  $fontDetailVariant1: ['normal'],
  $fontDetailWeight1: 'normal',
  $fontDetailSize1: 13,
  $fontDetailHeight1: 17,
  $fontDetailPaddingTop1: 0,
  $fontDetailPaddingRight1: 0,
  $fontDetailPaddingBottom1: 0,
  $fontDetailPaddingLeft1: 0,
  $fontDetailLetterSpacing1: 0.1,
  $fontDetailStyle2: 'normal',
  $fontDetailVariant2: ['normal'],
  $fontDetailWeight2: 'normal',
  $fontDetailSize2: 12,
  $fontDetailHeight2: 16,
  $fontDetailPaddingTop2: 0,
  $fontDetailPaddingRight2: 0,
  $fontDetailPaddingBottom2: 0,
  $fontDetailPaddingLeft2: 0,
  $fontDetailLetterSpacing2: 0.1,
  $fontDetailStyle3: 'normal',
  $fontDetailVariant3: ['normal'],
  $fontDetailWeight3: 'normal',
  $fontDetailSize3: 10,
  $fontDetailHeight3: 14,
  $fontDetailPaddingTop3: 0,
  $fontDetailPaddingRight3: 0,
  $fontDetailPaddingBottom3: 0,
  $fontDetailPaddingLeft3: 0,
  $fontDetailLetterSpacing3: 0.1,

  $fontDetailBold: 'DetailBold',
  $fontDetailBoldStyle1: 'normal',
  $fontDetailBoldVariant1: ['normal'],
  $fontDetailBoldWeight1: 'normal',
  $fontDetailBoldSize1: 13,
  $fontDetailBoldHeight1: 17,
  $fontDetailBoldPaddingTop1: 0,
  $fontDetailBoldPaddingRight1: 0,
  $fontDetailBoldPaddingBottom1: 0,
  $fontDetailBoldPaddingLeft1: 0,
  $fontDetailBoldLetterSpacing1: 0.1,
  $fontDetailBoldStyle2: 'normal',
  $fontDetailBoldVariant2: ['normal'],
  $fontDetailBoldWeight2: 'normal',
  $fontDetailBoldSize2: 12,
  $fontDetailBoldHeight2: 16,
  $fontDetailBoldPaddingTop2: 0,
  $fontDetailBoldPaddingRight2: 0,
  $fontDetailBoldPaddingBottom2: 0,
  $fontDetailBoldPaddingLeft2: 0,
  $fontDetailBoldLetterSpacing2: 0.1,
  $fontDetailBoldStyle3: 'normal',
  $fontDetailBoldVariant3: ['normal'],
  $fontDetailBoldWeight3: 'normal',
  $fontDetailBoldSize3: 10,
  $fontDetailBoldHeight3: 14,
  $fontDetailBoldPaddingTop3: 0,
  $fontDetailBoldPaddingRight3: 0,
  $fontDetailBoldPaddingBottom3: 0,
  $fontDetailBoldPaddingLeft3: 0,
  $fontDetailBoldLetterSpacing3: 0.1,

  /* ICON */
  $iconFamily: 'auroraicons',
  $iconGlyphs: require('@lookiero/aurora-iconfont/dist/glyphs.json'),
  $iconSizeSmall: 16,
  $iconSize: 24,
  $iconSizeLarge: 40,

  /* INPUT */
  $fontInput: 'Body',
  $fontInputStyle: 'normal',
  $fontInputVariant: ['normal'],
  $fontInputWeight: '300',
  $fontInputSize: 15,
  $fontInputHeight: 20,
  $fontInputPaddingTop: isWeb || isAndroid ? 18 : 15,
  $fontInputPaddingRight: 16,
  $fontInputPaddingBottom: 18,
  $fontInputPaddingLeft: 16,
  $fontInputLetterSpacing: -0.3,
  $inputOptionSize: 22,
  $inputSize: 56,
  $inputSizeSmall: 40,
  $inputSizeLarge: 72,

  /* LAYOUT */
  $layoutMaxWidth: 1280,

  /* MOTION */
  $motionCollapse: 250,
  $motionExpand: 300,
  $motionSkeleton: 1200,
  $motionSlow: 5000,
  $motionEasing: {
    linear: () => Easing.linear(1),
    spring: () => Easing.bezier(0.47, 1.64, 0.41, 0.8),
    quad: () => Easing.inOut(Easing.quad),
  },

  /* NEW SPACING TOKENS */
  $space025: 1,
  $space05: 2,
  $space1: 4, // base
  $space2: 8,
  $space3: 12,
  $space4: 16,
  $space5: 20,
  $space6: 24,
  $space8: 32,
  $space10: 40,
  $space12: 48,
  $space16: 64,
  $space20: 80,
  $space24: 96,
  $space28: 112,
  $space32: 128,

  // TODO: Deprecate in the next release
  /* SPACING */
  $spaceXXS: 2,
  $spaceXS: 4,
  $spaceS: 8,
  $spaceM: 16,
  $spaceL: 24,
  $spaceXL: 32,
  $spaceXXL: 40,
  $spaceXXXL: 48,

  // TODO: Deprecate in the next release
  /* LAYOUT */
  $layoutXXS: 16,
  $layoutXS: 24,
  $layoutS: 32,
  $layoutM: 48,
  $layoutL: 64,
  $layoutXL: 80,
  $layoutXXL: 96,
  $layoutXXXL: 120,
};
