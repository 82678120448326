// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';
import { Path, Svg } from 'react-native-svg';

import { ALIGN, ButtonIcon, COLOR, Header, Link, SIZE, SPACE, View } from '@package';

const Logo = () => {
  return (
    <Svg width="102" height="20" viewBox="0 0 102 20" fill="none">
      <Path
        d="M86.5744 5.96272C85.9218 5.81552 85.3428 5.97622 84.8325 6.39084C84.3246 6.80424 83.9787 7.34154 83.7051 7.92667C83.5653 8.22599 83.4451 8.53389 83.3163 8.83811C83.2807 8.83198 83.2868 8.8332 83.2525 8.82584C83.2181 7.85798 83.1825 6.89011 83.1482 5.91611C83.1482 5.91366 83.1457 5.91243 83.1433 5.91243C81.5854 6.09766 80.0729 6.27676 78.5603 6.46199C78.4843 6.4718 78.3543 6.54173 78.3555 6.57853C78.3592 6.66072 78.4107 6.77357 78.4781 6.81651C78.8866 7.07411 79.1013 7.45316 79.1774 7.91318C79.2215 8.18305 79.2522 8.45906 79.2534 8.73139C79.2608 11.6203 79.2632 14.5079 79.2596 17.3968C79.2596 17.7415 79.2362 18.0887 79.1823 18.4285C79.1136 18.8676 78.921 19.2295 78.4916 19.4368C78.4855 19.4405 78.472 19.4491 78.4634 19.454C78.3371 19.5374 78.3996 19.7312 78.5726 19.7312H84.8092C85.0067 19.7312 85.0471 19.454 84.8582 19.3963C84.857 19.3963 84.8545 19.3963 84.8533 19.3951C83.9529 19.1191 83.3948 18.5327 83.342 17.5784C83.2831 16.5099 83.2991 11.2142 83.2991 11.2142C83.3433 10.1016 83.4905 9.40853 83.9725 8.64429C84.3528 8.04198 84.8594 7.87147 85.5538 8.04075C85.7893 8.09841 86.1364 8.2358 86.3757 8.26401C87.0479 8.34375 87.4588 7.97329 87.5496 7.2802C87.6416 6.63864 87.1706 6.09766 86.5756 5.96272H86.5744Z"
        fill="#1B1B1B"
      />
      <Path
        d="M56.7433 18.2419C54.9057 15.6278 53.4067 12.9928 51.9383 10.1579C51.8512 9.99107 51.9187 9.89784 52.0389 9.79602C52.8044 9.11643 53.4815 8.49082 54.2396 7.8468C54.9278 7.24203 55.6135 6.78815 56.4783 6.56367C56.4906 6.55999 56.5029 6.55508 56.5151 6.55018C56.6354 6.48884 56.5912 6.29625 56.4563 6.29011C56.4538 6.29011 56.4514 6.29011 56.4489 6.29011C55.6344 6.28275 54.8198 6.28521 54.0065 6.28521C53.1822 6.28521 52.3579 6.28153 51.5335 6.29011C51.5163 6.29011 51.5004 6.29379 51.4832 6.2987C51.3753 6.33305 51.3532 6.47657 51.444 6.54649C51.4562 6.55508 51.4685 6.5649 51.4808 6.57226C51.5298 6.60538 51.5948 6.61396 51.6513 6.63727C52.2757 6.89733 52.5922 7.46652 52.3419 8.09091C52.1653 8.53007 51.8831 8.95696 51.5519 9.29798C50.6037 10.2757 49.6088 11.208 48.6287 12.1537C48.4987 12.2789 48.4287 12.3721 48.5465 12.5622C49.6395 14.3238 50.7411 16.0792 52.0536 17.6911C52.7124 18.5007 53.5195 19.0785 54.5254 19.39C55.5497 19.7078 56.5875 19.7372 57.6621 19.8071C57.9308 19.8243 57.9271 19.5581 57.8081 19.4489C57.456 19.0429 57.0512 18.6761 56.7445 18.2394L56.7433 18.2419Z"
        fill="#1B1B1B"
      />
      <Path
        d="M60.7001 4.70184C62.2298 4.71043 63.1204 3.96705 63.1425 3.11695C63.167 2.1822 62.1452 1.46949 60.77 1.45109C59.3839 1.43146 58.3166 2.12945 58.2982 3.06788C58.259 3.85787 59.0747 4.69326 60.7013 4.70184H60.7001Z"
        fill="#1B1B1B"
      />
      <Path
        d="M48.8382 19.3991C48.4334 19.2028 48.231 18.8729 48.2015 18.4337C48.1844 18.1798 48.1745 17.9258 48.1745 17.6719C48.1721 15.14 48.1733 12.8068 48.1733 10.2749H48.1696C48.4861 0.896791 44.2147 0.192664 43.8934 0.0430064C43.6762 -0.0588097 43.4701 0.0442331 43.5904 0.282213C43.9731 1.1544 44.1534 3.67772 44.1534 6.33843V11.8758H44.1571L44.1583 17.7418C44.1583 17.9651 44.1485 18.1896 44.1313 18.4116C44.0958 18.8704 43.8884 19.2163 43.4603 19.4249C43.4603 19.4249 43.4579 19.4249 43.4579 19.4261C43.3119 19.4997 43.372 19.7352 43.5352 19.7401C43.5376 19.7401 43.5401 19.7401 43.5425 19.7401C45.2832 19.745 47.0239 19.7438 48.7646 19.7426C48.8357 19.7426 48.9204 19.7438 48.967 19.7107C49.0246 19.6702 49.043 19.5843 49.0111 19.523C48.978 19.4604 48.9093 19.4335 48.837 19.3991H48.8382Z"
        fill="#1B1B1B"
      />
      <Path
        d="M62.9108 13.6443V9.06871L62.8776 6.63248V6.40432C62.8776 6.30005 62.7881 6.21786 62.6851 6.22645C61.1922 6.34176 59.7361 6.45339 58.2186 6.56992C58.0592 6.58219 57.9856 6.79318 58.1156 6.88396C58.1156 6.88396 58.1168 6.88396 58.118 6.88519C58.5388 7.17101 58.7302 7.58563 58.7866 8.0665C58.8406 8.52896 58.8896 8.99634 58.8909 9.46248H58.8945V13.9878C58.8945 19.308 62.8212 19.9078 63.1696 19.9729C63.3646 20.0097 63.594 19.9385 63.4738 19.7005C63.0911 18.8283 62.9108 16.305 62.9108 13.6443Z"
        fill="#1B1B1B"
      />
      <Path
        d="M75.0681 6.79706C75.0681 6.79706 75.0607 6.79461 75.0558 6.79215C73.6267 5.7421 71.7953 5.57404 70.1036 5.95187C68.1213 6.3947 66.8099 7.68765 66.004 9.5093C65.2851 11.1347 65.2177 12.8373 65.5096 14.5608C65.9881 17.3847 68.0244 19.702 71.0151 19.9657C72.1952 20.07 73.3348 19.9645 74.3431 19.2603C75.533 18.4286 76.1181 17.2179 76.5217 15.8808C76.5242 15.8746 76.5254 15.8673 76.5266 15.8513C76.5389 15.7311 76.3929 15.687 76.3009 15.7422C76.2813 15.7532 76.2727 15.7606 76.2506 15.779C75.0497 16.9333 72.5386 17.0314 71.0261 15.9041C70.2545 15.3288 69.7037 14.6124 69.3222 13.788C68.9395 12.8655 70.1184 12.4166 70.9071 12.4166C73.8328 12.4166 76.15 11.9725 76.6174 10.4097C77.2627 8.25316 75.0656 6.79829 75.0656 6.79829L75.0681 6.79706ZM70.425 11.5309C69.6583 11.6941 69.1542 12.1577 68.9285 12.6914C68.9272 12.6852 68.9248 12.6791 68.9235 12.673C68.6819 11.7762 68.0734 9.66509 69.2658 7.55271C69.662 6.85104 70.4888 6.30761 71.3794 6.43273C72.0467 6.52596 72.6699 6.84368 72.9827 7.52204C73.1569 7.89741 73.2575 8.31694 73.309 8.81621C73.5151 11.4916 71.6272 11.277 70.4238 11.5321L70.425 11.5309Z"
        fill="#1B1B1B"
      />
      <Path
        d="M40.2399 7.88872C39.6156 7.19196 38.9065 6.65466 38.1128 6.27807C37.3179 5.90147 36.4715 5.69538 35.5711 5.65981C34.6707 5.62546 33.7507 5.78616 32.811 6.14436C31.8873 6.49519 31.0973 6.98587 30.4398 7.61272C29.7823 8.24079 29.2818 8.95963 28.9396 9.76926C28.5973 10.5789 28.4244 11.4511 28.4219 12.387C28.4194 13.3218 28.5998 14.2676 28.9641 15.2232C29.3346 16.1959 29.8314 17.0301 30.4545 17.7269C31.0777 18.4236 31.7892 18.9646 32.5866 19.3498C33.3839 19.735 34.2377 19.9435 35.1455 19.9754C36.0532 20.0073 36.9696 19.8478 37.892 19.4958C38.8145 19.1449 39.6057 18.6555 40.2632 18.0274C40.9208 17.4006 41.4188 16.6768 41.7586 15.8586C42.0984 15.0404 42.2689 14.1645 42.2726 13.2286C42.275 12.2938 42.091 11.3394 41.7218 10.3667C41.3575 9.41106 40.8643 8.58426 40.2399 7.8875V7.88872ZM37.6945 18.9769C36.9524 19.259 36.182 18.9437 35.381 18.0311C34.5812 17.1184 33.801 15.6648 33.0417 13.6702C32.2885 11.6927 31.9094 10.1005 31.9033 8.89462C31.8971 7.69 32.2652 6.94539 33.0073 6.66325C33.7495 6.38111 34.5186 6.69269 35.316 7.59677C36.1133 8.50085 36.8886 9.94222 37.6406 11.9197C38.3999 13.9143 38.7838 15.5176 38.7924 16.732C38.801 17.9464 38.4355 18.6935 37.6933 18.9756L37.6945 18.9769Z"
        fill="#1B1B1B"
      />
      <Path
        d="M101.155 10.3667C100.791 9.41106 100.298 8.58427 99.6735 7.8875C99.0491 7.19074 98.3401 6.65344 97.5464 6.27684C96.7515 5.90025 95.9051 5.69416 95.0047 5.65859C94.1043 5.62424 93.1843 5.78494 92.2446 6.14313C91.3209 6.49397 90.5309 6.98465 89.8734 7.61149C89.2159 8.23957 88.7154 8.95841 88.3732 9.76803C88.0309 10.5777 87.8579 11.4498 87.8555 12.3858C87.853 13.3206 88.0334 14.2663 88.3977 15.2219C88.7682 16.1947 89.265 17.0289 89.8881 17.7256C90.5113 18.4224 91.2228 18.9634 92.0201 19.3486C92.8175 19.7337 93.6713 19.9423 94.579 19.9742C95.4868 20.0061 96.4032 19.8466 97.3256 19.4945C98.2481 19.1437 99.0393 18.6543 99.6968 18.0262C100.354 17.3993 100.852 16.6756 101.192 15.8574C101.532 15.0392 101.702 14.1633 101.706 13.2273C101.709 12.2926 101.525 11.3382 101.155 10.3654V10.3667ZM97.1294 18.9756C96.3872 19.2578 95.6168 18.9425 94.8158 18.0299C94.016 17.1172 93.2358 15.6636 92.4765 13.6689C91.7233 11.6915 91.3442 10.0992 91.3381 8.8934C91.332 7.68878 91.7 6.94417 92.4421 6.66203C93.1843 6.37989 93.9534 6.69147 94.7508 7.59555C95.5481 8.49963 96.3234 9.941 97.0754 11.9184C97.8347 13.9131 98.2187 15.5164 98.2273 16.7308C98.2358 17.944 97.8703 18.6923 97.1281 18.9744L97.1294 18.9756Z"
        fill="#1B1B1B"
      />
      <Path
        d="M24.8181 7.88872C24.1937 7.19196 23.4846 6.65466 22.691 6.27807C21.8961 5.90147 21.0496 5.69538 20.1492 5.65981C19.2488 5.62546 18.3288 5.78616 17.3892 6.14436C16.4655 6.49519 15.6755 6.98587 15.0179 7.61272C14.3604 8.24079 13.8599 8.95963 13.5177 9.76926C13.1754 10.5789 13.0025 11.4511 13 12.387C12.9976 13.3218 13.1779 14.2676 13.5422 15.2232C13.9127 16.1959 14.4095 17.0301 15.0327 17.7269C15.6558 18.4236 16.3673 18.9646 17.1647 19.3498C17.962 19.735 18.8158 19.9435 19.7236 19.9754C20.6313 20.0073 21.5477 19.8478 22.4702 19.4958C23.3926 19.1449 24.1839 18.6555 24.8414 18.0274C25.4989 17.4006 25.9969 16.6768 26.3367 15.8586C26.6765 15.0404 26.847 14.1645 26.8507 13.2286C26.8532 12.2938 26.6692 11.3394 26.2999 10.3667C25.9356 9.41106 25.4425 8.58426 24.8181 7.8875V7.88872ZM22.2727 18.9769C21.5305 19.259 20.7601 18.9437 19.9591 18.0311C19.1593 17.1184 18.3791 15.6648 17.6198 13.6702C16.8666 11.6927 16.4875 10.1005 16.4814 8.89462C16.4753 7.69 16.8433 6.94539 17.5854 6.66325C18.3276 6.38111 19.0967 6.69269 19.8941 7.59677C20.6914 8.50085 21.4667 9.94222 22.2187 11.9197C22.978 13.9143 23.362 15.5176 23.3706 16.732C23.3791 17.9452 23.0136 18.6935 22.2714 18.9756L22.2727 18.9769Z"
        fill="#1B1B1B"
      />
      <Path
        d="M11.715 13.0458C11.6205 13.0458 11.5396 13.1108 11.5163 13.2016C10.9925 15.249 10.2847 16.7406 9.39285 17.6754C8.47773 18.6347 7.3001 19.1131 5.8575 19.1131H5.74464C5.16441 19.1131 5.05156 18.8199 5.05156 18.0716C5.05156 17.9587 5.04665 17.8471 5.04052 17.7343V9.49205C5.04052 9.49205 5.04052 9.48101 5.03929 9.47611C5.09326 0.853629 1.05742 0.185078 0.745834 0.0391001C0.528708 -0.062716 0.322622 0.0403268 0.442839 0.278307C0.798583 1.08916 0.980134 3.32543 1.00344 5.77392V17.3724C1.00344 18.0409 0.924933 18.5328 0.765462 18.8493C0.623164 19.1339 0.402358 19.3326 0.10427 19.4467C0.0404811 19.4713 0 19.5351 0 19.6037C0 19.6823 0.0637885 19.746 0.141071 19.746H11.2476C11.3225 19.746 11.3421 19.7166 11.3494 19.6589L11.9051 13.2543C11.915 13.1415 11.8266 13.0458 11.7138 13.0458H11.715Z"
        fill="#1B1B1B"
      />
    </Svg>
  );
};

export const HeaderChapter = () => {
  const { boolean, event, object, select, text } = useStoryteller();

  const commons = {
    color: select('color', COLOR.BG_BASE, { options: Object.values(COLOR) }) as COLOR,
    marginBottom: SPACE.SPACE_4,
  };

  return (
    <View backgroundColor={COLOR.BG_PRIMARY_LIGHT} flex={SIZE.XS}>
      <Header {...commons} logo={<Logo />} />

      <Header
        {...commons}
        logo={<Logo />}
        fullWidth={boolean('fullWidth', true)}
        notification={boolean('notification', false)}
        style={object('style', {})}
        onMenu={() => event('onMenu')}
      />
      <Header {...commons} onClose={() => event('onClose')} />

      <Header {...commons} onBack={() => event('onBack')} title={text('title', 'Section Title')} />

      <Header
        {...commons}
        onBack={() => event('onBack')}
        title={text('title', 'Section Title')}
        onClose={() => event('onClose')}
      />

      <Header {...commons} logo={<Logo />} notification={boolean('notification', true)} onMenu={() => event('onMenu')}>
        <Link
          action
          align={ALIGN.CENTER}
          href={'https://lookiero.es'}
          underlined
          onPress={({ href }) => event('onPress', [href])}
          marginRight={SPACE.SPACE_4}
        >
          {'Log in'}
        </Link>
        <ButtonIcon
          alignSelf={ALIGN.CENTER}
          color={COLOR.ICON}
          name="heart"
          onPress={() => event('onHeart')}
          marginRight={SPACE.SPACE_4}
          small
        />
      </Header>
    </View>
  );
};

HeaderChapter.displayName = '✳️ Molecules/Header';
