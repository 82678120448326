// TODO: This is not a real "hook"

export const useBanProps = (
  props: Record<string, unknown> = {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bannedProps: Record<string, any> = [],
): Record<string, unknown> => {
  const safeProps: Record<string, unknown> = {};

  Object.keys(props).forEach((prop) => {
    if (!bannedProps.includes(prop)) safeProps[prop] = props[prop];
  });

  return safeProps;
};
